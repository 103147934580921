// 类型管理
// sle
<template>
  <div class="box flex-row justify-center">
    <div class="main flex justify-between">
      <!-- 左侧 -->
      <div
        :style="{ minHeight: minHeight + 'px' }"
        class="leftSide flex-column"
      >
        <!-- 搜索框 -->
        <div v-if="false" class="typeSearch flex-centent">
          <a-input-search
            v-model:value="left.searchInput"
            class="searchInput"
            placeholder="搜索"
            allow-clear
            @search="leftSearch"
            @change="leftSearchChange"
            :maxlength="20"
          >
          </a-input-search>
          <RedoOutlined @click="refresh" />
        </div>
        <div class="list">
          <div class="all flex-row">
            <a
              @click="choiseAll()"
              :style="left.choise.id == 'all' ? left.ischoise : left.notChoise"
              class="title"
              :title="left.title"
            >
              {{ left.title }}
            </a>
            <div class="leftitem">
              <a @click="addLeftItem()" class="plusicon">
                <PlusOutlined style="fontsize: 20px"></PlusOutlined>
              </a>
            </div>
          </div>
          <!-- 当前类型列表 -->
          <div class="leftList">
            <a-spin :spinning="left.spinning">
              <a-list
                item-layout="horizontal"
                :data-source="left.list"
                :split="false"
              >
                <template #renderItem="{ item }">
                  <div class="leftitem flex-row">
                    <a class="flex-row align-center leftItemTitle">
                      <a-list-item
                        @click="leftItemChange(item)"
                        :style="
                          left.choise.id == item.id
                            ? left.ischoise
                            : left.notChoise
                        "
                        :title="item.externalName"
                      >
                        <span class="externalName">{{
                          item.externalName
                        }}</span>
                      </a-list-item>
                      <a-dropdown
                        :disabled="moreDisabled"
                        :trigger="['click']"
                        v-if="item.id != ''"
                      >
                        <a
                          :style="
                            left.choise.id == item.id
                              ? left.ischoise
                              : left.notChoise
                          "
                          @click.prevent
                        >
                          <MoreOutlined />
                          <!-- &nbsp;&nbsp;&nbsp;· · · -->
                        </a>
                        <template #overlay>
                          <a-menu>
                            <a-menu-item
                              key="edit"
                              @click="editLeftItem(item.id)"
                            >
                              <div class="flex">
                                <img src="@/assets/bianji_icon.png" alt="" />
                                <a style="color: black; margin-left: 6px"
                                  >编辑</a
                                >
                              </div>
                            </a-menu-item>
                            <a-menu-item key="del">
                              <a-popconfirm
                                cancelText="取消"
                                okText="确认"
                                :title="'是否确认删除 ' + item.externalName"
                                @confirm="submitDelLeftItem(item.id)"
                              >
                                <div class="flex">
                                  <img src="@/assets/icon_shanchu.png" alt="" />
                                  <a style="color: black; margin-left: 6px"
                                    >删除</a
                                  >
                                </div>
                              </a-popconfirm>
                            </a-menu-item>
                          </a-menu>
                        </template>
                      </a-dropdown>
                    </a>
                  </div>
                </template>
              </a-list>
            </a-spin>
          </div>
        </div>
      </div>

      <!-- 右侧 -->
      <div class="right flex-column" :style="{ minHeight: minHeight + 'px' }">
        <div class="head flex-row align-center justify-between">
          <div class="title">{{ left.choise.name }}</div>
          <div class="flex right-button">
            <!-- <div
              v-if="headButtonVisible"
              class="flex"
              style="
                margin-right: 20px;
                border-right: 1px solid #dddddd;
                padding-right: 20px;
              "
            >
              <img class="img" src="@/assets/yi_bianji.png" />
              <div class="button" @click="jurisdiction">编辑权限</div>
            </div> -->
            <div class="flex" v-if="invitationDisabled">
              <img class="img" src="@/assets/invite_blue.svg" />
              <div class="button" @click="invitationClick">邀请</div>
            </div>
            <div class="flex" v-if="!invitationDisabled">
              <img class="img" src="@/assets/invite_gray.svg" />
              <div class="buttonHide" :title="title">邀请</div>
            </div>
          </div>
          <!-- <div>
          <a-button class="button">编辑权限</a-button>
          <a-button class="button" @click="invitationClick">邀请</a-button>
        </div> -->
        </div>
        <div class="line_h"></div>
        <div class="buttonGroup">
          <div class="departmentButtons flex-row align-center justify-between">
            <a-input-search
              class="search"
              v-model:value="right.searchInput"
              placeholder="搜索"
              allow-clear
              @search="rightSearch"
              @change="rightSearchChange"
              :maxlength="20"
            >
            </a-input-search>
            <span>
              <a-button
                :disabled="!disabled"
                class="button"
                :title="title"
                @click="addExternalGenre"
              >
                添加
              </a-button>
              <a-button class="button" v-show="isShow">导入</a-button>
              <a-button class="button" v-show="isShow">导出</a-button>
            </span>
          </div>
        </div>
        <div class="table">
          <a-spin :spinning="right.spinning">
            <a-table
              :columns="columns"
              :data-source="right.list"
              bordered
              :rowKey="(record) => record.id"
              :pagination="pagination"
              @change="paginationChange"
              :row-selection="{
                selectedRowKeys: right.selectedRows,
                onChange: onSelectChange,
                fixed: true,
              }"
              :scroll="{ x: 1300 }"
            >
              <!-- <a-table
            :columns="columns"
            :data-source="right.list"
            bordered
            :rowKey="(record) => record.id"
            :pagination="pagination"
            @change="paginationChange"
            :row-selection="{
              selectedRowKeys: right.selectedRows,
              onChange: onSelectChange,
              fixed: true,
            }"
            :scroll="{ x: 1200 }"
          > -->
              <template #externalGenreName="{ record }">
                <a-tooltip>
                  <template #title>{{ record.externalGenreName }}</template>
                  <span class="ecllipsis" style="width: 100%">
                    {{ record.externalGenreName }}
                  </span>
                </a-tooltip>
              </template>
              <template #isBind="{ record }">
                <a-tooltip>
                  <template #title>{{ record.isBind }}</template>
                  <span class="ecllipsis" style="width: 100%">
                    {{ record.isBind === false ? "否" : "是" }}
                  </span>
                </a-tooltip>
              </template>
              <template #company="{ record }">
                <a-tooltip>
                  <template #title>{{ record.company }}</template>
                  <span class="ecllipsis" style="width: 100%">
                    {{ record.company }}
                  </span>
                </a-tooltip>
              </template>
              <template #principalName="{ record }">
                <a-tooltip>
                  <template #title>{{ record.principalName }}</template>
                  <span class="ecllipsis" style="width: 100%">
                    {{ record.principalName }}
                  </span>
                </a-tooltip>
              </template>
              <template #personState="{ record }">
                <a-switch
                  :disabled="!disabled"
                  :value="record.personState"
                  v-model:checked="record.personState"
                  @change="editState(record, record.personState)"
                />
              </template>
              <template #operation="{ record }">
                <div class="editable-row-operations">
                  <span class="flex-row justify-around">
                    <a v-if="disabled" @click="editExternalGenre(record.id)"
                      >编辑</a
                    >
                    <div class="hide" v-if="!disabled" :title="title">
                      编 辑
                    </div>
                    <a-popconfirm
                      cancelText="取消"
                      okText="确认"
                      title="是否确认删除"
                      @confirm="deleteExternalGenre(record)"
                    >
                      <a v-if="disabled">删除</a>
                    </a-popconfirm>
                    <div class="hide" :title="title" v-if="!disabled">删除</div>
                  </span>
                </div>
              </template>
            </a-table>
          </a-spin>
        </div>
        <!-- 新增/编辑类型弹窗 -->
        <external-genre-edit-view
          ref="externalgenre"
          @editBeforeReload="editBeforeReload"
        />
        <!-- 新增外部联系人弹窗 -->
        <external-contact-edit-view
          ref="externalContact"
          :externalGenreList="externalGenreList"
          @externalGenreBeforeReload="externalGenreBeforeReload"
        />
        <!-- 邀请弹窗 -->
        <invitation-create-view ref="invitation" :isEmployee="false" />
        <!-- 停用确认框 -->
        <a-modal
          :maskClosable="false"
          v-model:visible="stopView"
          title="停用外部联系人"
          ok-text="确认"
          cancel-text="取消"
          @ok="editStateConfirm"
        >
          确认停用{{ right.choise.personName }}吗？
        </a-modal>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import api from '@/api/API'
import { minheight } from '../components/sameVariable'
import { isEmpty, addressBookJurisdiction, inviteJurisdiction, tenantIsComplete } from '@/assets/common.js'
import ExternalContactEditView from './ExternalContactEditView.vue'
import ExternalGenreEditView from './ExternalGenreEditView.vue'
import InvitationCreateView from '@/views/AddressBook/components/InvitationCreateView.vue'
import { getTableColumnData } from '@/api/tableAbout/tableAPI'
import { RedoOutlined, MoreOutlined, PlusOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    'external-contact-edit-view': ExternalContactEditView,
    'external-genre-edit-view': ExternalGenreEditView,
    'invitation-create-view': InvitationCreateView,
    MoreOutlined,
    RedoOutlined,
    PlusOutlined,
  },
  data () {
    return {
      isShow: false,
      title: '暂无权限',
      disabled: false,
      disabledTenant: false,
      invitationDisabled: false,
      moreDisabled: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      }, // 分页部分
      minHeight: '400',
      left: {
        title: '类型',
        leftType: '1', // 当前选中的类型
        searchInput: '', // 搜索栏内容
        isSearch: false, // 是否在搜索中
        spinning: false, // 是否加载
        list: [], // 左侧列表
        employeeSum: '', // 人员总数
        choise: {
          id: '', // 选中的id
          name: '', // 选中的名称
        }, // 当前选中
        ischoise: 'color:#3399ff', // 选中时颜色
        notChoise: 'color:black', // 未选中时颜色
      }, // 左侧
      right: {
        spinning: false, // 是否加载
        searchInput: '', // 搜索栏内容
        isSearch: false, // 是否在搜索中
        list: [], // 右侧列表
        selectedRows: [],
        choise: {
          id: null,
          person: null,
          personState: false,
        }, // 要被停用的外部联系人
      }, // 右侧
      columns: [],
      headButtonVisible: false, // 编辑按钮是否显示
      delListVisible: false, // 批量删除确认弹框是否显示
      externalGenreList: [], // 类型下拉
      stopView: false, // 停用弹窗是否显示
    }
  },
  // 页面加载事件
  created () {
    this.minHeight = minheight
    this.getTableColumnData()
    this.loadLeftList()
    this.left.choise.id = 'all'
    this.left.choise.name = this.left.title
    this.getAllTableList()
    this.getJurisdiction()
  },
  // 方法
  methods: {
    // jurisdiction () {
    //   window.location.href = '/EnterpriseManagement/Application'
    // },
    // 刷新
    refresh () {
      this.loadLeftList()
    },
    // 获取权限
    getJurisdiction () {
      this.disabled = addressBookJurisdiction()
      this.disabledTenant = tenantIsComplete()
      this.invitationDisabled = inviteJurisdiction()

      if (this.disabledTenant === false) {
        this.disabled = false
        this.invitationDisabled = false
      }
      if (this.disabled === true) {
        this.moreDisabled = null
      }
    },
    // 获取当前table的列信息
    async getTableColumnData () {
      const data = await getTableColumnData('ExternaLinkMan', 'addressBook', true)
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取列结构失败')
          console.log(err)
        })
      const list = data
      const arr = []
      list.map((item, index) => {
        arr.push(Object.assign({}, item, { width: 150 }))
      })
      this.columns = arr
    },
    // #region 左侧列表事件
    // 左侧搜索栏变化事件
    leftSearchChange () {
      if (isEmpty(this.left.searchInput) && this.left.isSearch) {
        this.left.isSearch = false
        this.loadLeftList()
      }
    },
    // 左侧搜索
    leftSearch (e) {
      if (isEmpty(this.left.searchInput)) {
        return
      }
      this.left.isSearch = true
      this.loadLeftList()
    },

    // 左侧标题 点击事件
    choiseAll () {
      // 当前选中项更新为标题，选中项id更新为all，当前所在页更新为1，不显示右侧按钮，选中项清空，页码总数为总人数，获取外部联系人列表
      this.right.selectedRows = []
      this.pagination.current = 1
      this.right.searchInput = ''
      this.right.isSearch = false
      this.left.choise.name = this.left.title
      this.left.choise.id = 'all'
      this.headButtonVisible = false
      this.pagination.total = this.left.employeeSum
      // console.log(this.pagination.total)
      this.getAllTableList()
    },
    // 左侧类型列表，选项变更事件
    leftItemChange (item) {
      this.right.selectedRows = []
      this.pagination.current = 1
      this.right.searchInput = ''
      this.right.isSearch = false
      this.left.choise.name = item.externalName
      this.left.choise.id = item.id
      this.headButtonVisible = !isEmpty(item.id)
      this.pagination.total = item.employeeCount
      // console.log(this.pagination.total)
      this.getListByLeftItem()
    },
    // 获取类型列表数据
    loadLeftList () {
      this.left.spinning = true
      api
        .get('/api/app/external-genre/external-genre-list-group', {
          params: {
            SearchInput: this.left.searchInput,
          },
        })
        .then(({ data }) => {
          this.left.spinning = false
          this.left.list = data
          // 若是正在搜索，则不走以下方法
          if (this.left.isSearch) {
            return
          }
          const selectList = []
          this.left.employeeSum = 0
          data.forEach(element => {
            // console.log(element)
            this.left.employeeSum += element.employeeCount
            if (!isEmpty(element.id)) {
              const ojb = {
                linkKey: element.id,
                name: element.externalName,
                code: element.externalCode,
              }
              selectList.push(ojb)
            }
          })
          this.externalGenreList = selectList
          if (this.left.choise.id === 'all') {
            this.first = false
            this.pagination.total = this.left.employeeSum
          }
        })
        .catch(err => {
          this.left.spinning = false
          // this.$message.error('获取类型列表失败')
          console.log(err)
        })
    },
    // #endregion

    // #region 类型管理部分
    // 添加类型按钮，呼出添加类型窗口
    addLeftItem () {
      this.$refs.externalgenre.addClick()
    },
    // 编辑按钮，呼出编辑类型窗口
    editLeftItem (id) {
      this.$refs.externalgenre.editClick(id)
    },
    // 新增，编辑后刷新
    editBeforeReload (name, id) {
      if (this.left.choise.id === id) {
        this.left.choise.name = name
      }
      this.loadLeftList()
    },
    // 确认删除类型操作
    submitDelLeftItem (id) {
      for (let i = 0; i < this.left.list.length; i++) {
        const element = this.left.list[i]
        if (element.id === id) {
          if (element.employeeCount !== 0) {
            this.$message.error('请先清空当前类型下的外部联系人')
            return
          }
          break
        }
      }
      this.left.spinning = true
      api
        .delete('/api/app/external-genre/external-genre', {
          params: {
            Id: id,
          },
        })
        .then(({ data }) => {
          this.$message.success('操作成功')
          this.loadLeftList()
          if (this.left.choise.id === id) {
            this.choiseAll()
          }
        })
        .catch(err => {
          this.left.spinning = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
    // #endregion

    // #region 右侧列表
    // 外部联系人列表 页码变更
    paginationChange (e) {
      this.pagination.current = e.current
      this.getRightData()
    },
    // 获取右侧列表数据
    getRightData () {
      if (this.left.choise.id === 'all') {
        this.getAllTableList()
      } else {
        this.getListByLeftItem()
      }
    },
    // 外部联系人列表选择事件
    onSelectChange (selectedRows) {
      this.right.selectedRows = selectedRows
    },

    // 获取所有人员数据
    getAllTableList () {
      this.right.spinning = true
      api
        .get('/api/app/external-contact/paging-external-contact-list-pC', {
          params: {
            SearchInput: this.right.searchInput,
            PageSize: this.pagination.pageSize,
            Pagination: this.pagination.current,
          },
        })
        .then(({ data }) => {
          this.right.spinning = false
          this.right.list = data.list
          if (this.right.searchInput === '') {
            this.pagination.total = this.left.employeeSum
          } else {
            this.pagination.total = data.pageCount
          }
          // this.pagination.total = data.length
        })
        .catch(err => {
          this.right.spinning = false
          // this.$message.error('获取外部联系人数据失败')
          console.log(err)
        })
    },
    // 根据指定类型显示其下外部联系人数据
    getListByLeftItem () {
      this.right.spinning = true
      api
        .get('/api/app/external-contact/contact-list-by-genre-id', {
          params: {
            input: this.left.choise.id,
            SearchInput: this.right.searchInput,
            PageSize: this.pagination.pageSize,
            Pagination: this.pagination.current,
          },
        })
        .then(({ data }) => {
          this.right.spinning = false
          this.right.list = data
          if (this.right.searchInput === '') {
            this.pagination.total = this.left.employeeSum
          } else {
            this.pagination.total = data.pageCount
          }
        })
        .catch(err => {
          this.right.spinning = false
          // this.$message.error('获取外部联系人数据失败')
          console.log(err)
        })
    },
    // #endregion

    // #region table 上方按钮
    // 右侧搜索栏变化事件
    rightSearchChange () {
      if (isEmpty(this.right.searchInput) && this.right.isSearch) {
        this.right.isSearch = false
        this.pagination.current = 1
        this.getRightData()
      }
    },
    // 右侧搜索
    rightSearch (e) {
      if (isEmpty(this.right.searchInput)) {
        return
      }
      this.pagination.current = 1
      this.right.isSearch = true
      this.getRightData()
    },

    // 添加外部联系人按钮，呼出添加页面
    addExternalGenre () {
      const externalGenreId = this.left.choise.id
      this.$refs.externalContact.addClick(externalGenreId)
    },
    // 编辑后刷新
    externalGenreBeforeReload () {
      this.left.pagination = 1
      this.loadLeftList()
      this.getRightData()
    },

    // 邀请按钮
    invitationClick () {
      this.$refs.invitation.invitationViewShow()
    },
    // #endregion

    // #region 外部联系人按钮
    // 编辑外部联系人按钮
    editExternalGenre (id) {
      this.$refs.externalContact.editClick(id)
    },
    // 删除外部联系人（单个）操作
    deleteExternalGenre (e) {
      this.right.spinning = true
      api
        .delete('/api/app/external-contact/external-contact', {
          params: {
            Id: e.id,
            // 删除方式 被管理员移除
            Type: 0,
          },
        })
        .then(({ data }) => {
          this.$message.success('操作成功')
          this.loadLeftList()
          this.getRightData()
        })
        .catch(err => {
          this.right.spinning = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
    // 更改状态
    editState (e, personState) {
      const obj = {
        id: e.id,
        personState: personState,
        personName: e.personName,
      }
      if (personState) {
        this.right.choise = obj
        this.editStateConfirm()
      } else {
        this.stopView = true
        for (let i = 0; i < this.right.list.length; i++) {
          const element = this.right.list[i]
          if (element.id === e.id) {
            this.right.list[i].personState = !e.personState
            this.right.choise = obj
            return
          }
        }
      }
    },
    // 更改状态确认
    editStateConfirm () {
      const obj = this.right.choise
      this.spinning = true
      api
        .post('/api/app/external-contact/control-enabled-state', obj)
        .then(({ data }) => {
          this.$message.success('操作成功')
          this.getRightData()
          this.stopView = false
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
    // #endregion
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";
.box {
  width: 100%;
  .main {
    width: 82%;
    .leftSide {
      border-radius: 5px;
      width: 14%;
      height: calc(90% - 144px);
      background: white;
      position: fixed;
      z-index: 10;
      min-height: 410px;

      .choise {
        color: #3399ff;
      }
      .typeSearch {
        margin: 20px 0 10px;
        width: 100%;
        height: 36px;
        .searchInput {
          width: 75%;
          background: #fff;
          margin-right: 10px;
        }
      }
      .leftType {
        margin-top: 10px;
        width: 100%;
        height: 36px;
        .radioLeft {
          border-radius: 2px 0px 0px 2px;
        }
        .radioRight {
          border-radius: 0px 2px 2px 0px;
        }
      }
      .list {
        max-height: 86%;
        overflow: auto;
        overflow-x: hidden;
      }
      .all {
        margin-top: 10px;
        padding-left: 10%;
        width: 100%;
        height: 36px;
        font-size: 18px;
        letter-spacing: 0px;
        a: {
          color: black;
        }
        .leftitem {
          margin-left: 10%;
          width: 100%;
          .plusicon {
            margin-left:5px;
            font-size: 18px;
            position: absolute;
            left: 86%;
            color: grey;
          }
          .plusicon::before {
            content: "添加岗位";
            position: absolute;
            width: 100px;
            background-color: #062b45;
            color: white;
            text-align: center;
            padding: 10px;
            line-height: 1.2;
            border-radius: 6px;
            z-index: 1;
            opacity: 0;
            transition: opacity 0.6s;
            margin-left: 100%;
            bottom: -10px;
            font-size: 0.75em;
            visibility: hidden;
            word-break: break-all;
          }
          .plusicon:hover:before {
            opacity: 1;
            visibility: visible;
          }
        }
      }
      .title {
        width: 85%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .leftList {
        width: 100%;
        margin-top: 5px;
        .leftitem {
          margin-left: 10%;
          width: 94%;
        }
        .leftItemTitle {
          width: 76%;
        }
        .ant-list-item {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .externalName {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .ant-dropdown-trigger {
          position: absolute;
          left: 87%;
          // margin-top: -8px;
        }
      }
    }
    .right {
      margin-left: 19%;
      width: 81%;
      background-color: #fff;
      border-radius: 5px;
      .head {
        letter-spacing: 1px;
        background: #fff;
        padding: 0px 20px;
        border-radius: 5px;
        height: 60px;
        font-size: 22px;
        .right-button {
          position: relative;
          // top: 20px;
        }
        .button {
          font-size: 16px;
          cursor: pointer;
          color: #3479db;
        }
        .img {
          width: 22px;
          height: 22px;
          margin-right: 10px;
        }
      }
      .buttonGroup {
        height: 80px;
        .button {
          margin-right: 20px;
        }
        .departmentButtons {
          height: 100%;
        }
        .search {
          width: 200px;
          margin-left: 24px;
        }
        .invite {
          color: black;
          margin-left: 40px;
        }
      }
      .table {
        padding: 0px 24px;
        margin: 0;
      }
      .editHide {
        font-size: 16px;
        color: #b7b7b7;
        cursor: not-allowed;
      }
      .hide {
        color: #b7b7b7;
        cursor: not-allowed;
      }
    }
  }
}

.list::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 3px;
}
.list::-webkit-scrollbar-thumb {
  background: #d1d1d1;
  border-radius: 10px;
}
.list::-webkit-scrollbar-thumb:hover {
  background: #d1d1d1;
}

::v-deep(.ant-table-bordered .ant-table-thead) > tr > th {
  text-align: center;
  border-right: none;
  padding: 12px 10px;
}
::v-deep(.ant-table-bordered .ant-table-tbody) > tr > td {
  text-align: center;
  padding: 12px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-right: none;
}
::v-deep(.ant-table-bordered .ant-table-fixed-right) table {
  border-right: 1px solid #f0f0f0;
}
</style>
