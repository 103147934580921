// 外部联系人新增，编辑弹窗
// sle
<template>
  <div id="ExternalContactEditView">
    <a-modal
      :confirmLoading="spinning"
      v-model:visible="visible"
      :maskClosable="false"
      :title="title"
      ok-text="保存"
      cancel-text="取消"
      :width="400"
      @ok="submitEditClick"
      @cancel="closeView"
    >
      <a-spin :spinning="spinning">
        <a-form
          :model="obj"
          ref="formRef"
          :rules="rules"
          autocomplete="off"
          :labelCol="{ span: 5 }"
          :wrapperCol="{ span: 18 }"
          labelAlign="left"
        >
          <a-form-item label="姓名" required name="personName">
            <a-input
              class="input"
              v-model:value="obj.personName"
              required
              :maxlength="15"
            />
          </a-form-item>
          <a-form-item label="电话" required name="phone">
            <a-input
              class="input"
              v-model:value="obj.phone"
              required
              :maxlength="11"
              :disabled="obj.isBind"
            />
            <label v-if="obj.isBind" class="isBind"
              >已绑定用户信息，无法修改手机号</label
            >
          </a-form-item>
          <a-form-item label="邮箱" required name="email">
            <a-input class="input" v-model:value="obj.email" :maxlength="40" />
          </a-form-item>
          <a-form-item label="公司" name="company">
            <a-input
              class="input"
              v-model:value="obj.company"
              :maxlength="60"
            />
          </a-form-item>
          <a-form-item label="职位" name="position">
            <a-input
              class="input"
              v-model:value="obj.position"
              :maxlength="20"
            />
          </a-form-item>
          <a-form-item label="地址" name="address">
            <a-input
              class="input"
              v-model:value="obj.address"
              :maxlength="150"
            />
          </a-form-item>
          <a-form-item label="类型" name="externalGenreId">
            <a-select
              v-model:value="obj.externalGenreId"
              placeholder="请选择外部联系人类型"
              :allowClear="true"
            >
              <a-select-option
                v-for="r in externalGenreList"
                :key="r.linkKey"
                :value="r.linkKey"
                >{{ r.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="负责人" required name="principalId">
            <a-select
              v-model:value="obj.principalId"
              placeholder="请选择负责人"
              :allowClear="true"
            >
              <a-select-option
                v-for="r in principaList"
                :key="r.linkKey"
                :value="r.linkKey"
                >{{ r.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import api from '@/api/API'

export default defineComponent({
  emits: ['externalGenreBeforeReload'],
  props: {
    externalGenreList: {
      type: Array,
    },
  },
  data () {
    return {
      add: false, // 是否新增操作
      visible: false, // 页面是否显示
      spinning: false, // 是否加载
      title: '', // 标题
      okTitle: '', // 确认弹框
      principaList: [], // 负责人list
      obj: {
        id: null, // 外部联系人Id
        personName: '', // 姓名
        phone: '', // 电话
        email: '', // 邮箱
        company: '', // 公司
        position: '', // 职位
        address: '', // 地址
        principalId: '', // 负责人
        externalGenreId: null, // 类型
      }, // 编辑的实体类
      rules: {
        personName: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 15,
            message: '长度请控制在15以内',
            trigger: 'blur',
          },
        ],
        phone: [
          {
            required: true,
            message: '请输入手机号',
            trigger: 'blur',
          },
          {
            min: 11,
            max: 11,
            message: '请输入正确的11位手机号',
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: '请输入邮箱',
            trigger: 'blur',
          },
          {
            min: 1,
            max: 60,
            message: '长度请控制在60以内',
            trigger: 'blur',
          },
        ],
        company: [
          {
            min: 1,
            max: 60,
            message: '长度请控制在60以内',
            trigger: 'blur',
          },
        ],
        position: [
          {
            min: 1,
            max: 20,
            message: '长度请控制在20以内',
            trigger: 'blur',
          },
        ],
        address: [
          {
            min: 1,
            max: 150,
            message: '长度请控制在150以内',
            trigger: 'blur',
          },
        ],
        principalId: [
          {
            required: true,
            message: '请选择负责人',
            trigger: 'change',
          },
        ],
      }, // 验证规则
    }
  },
  methods: {
    // 获取外部联系人信息
    getSelectData (id) {
      this.spinning = true
      api
        .get('/api/app/external-contact/external-contact', {
          params: {
            Id: id,
          },
        })
        .then(({ data }) => {
          this.visible = true
          this.add = false
          this.title = '编辑信息'
          this.getEmployeeSelectList()
          this.spinning = false
          this.obj = data
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('获取外部联系人信息失败')
          console.log(err)
        })
    },
    // 获取员工下拉数据
    getEmployeeSelectList (id) {
      api
        .get('/api/app/employee/select-list-by-depment-id')
        .then(({ data }) => {
          this.principaList = data
        })
        .catch(err => {
          // this.$message.error('获取成员数据失败')
          console.log(err)
        })
    },

    // 添加外部联系人按钮，呼出添加页面
    addClick (externalGenreId) {
      this.visible = true
      this.add = true
      this.title = '添加外部联系人'
      this.getEmployeeSelectList()
      this.obj.externalGenreId = externalGenreId === 'all' ? null : externalGenreId
    },
    // 编辑按钮，呼出编辑页面
    editClick (id) {
      this.getSelectData(id)
    },
    // 取消，关闭外部联系人信息添加页面
    closeView () {
      this.visible = false
      this.add = false
      this.title = ''
      this.$refs.formRef.resetFields()
      this.obj = {}
    },
    // 确认新增外部联系人信息操作
    submitEditClick () {
      this.$refs.formRef
        .validate()
        .then(() => {
          this.spinning = true
          const obj = this.obj
          api
            .post('/api/app/external-contact/or-update-external-contact', obj)
            .then(({ data }) => {
              this.$message.success('操作成功')
              this.$emit('externalGenreBeforeReload')
              this.closeView()
              this.spinning = false
            })
            .catch(err => {
              this.spinning = false
              // this.$message.error('操作失败')
              console.log(err)
            })
        })
        .catch(error => {
          console.log('error', error)
        })
    },
  },
})
</script>

<style  lang="scss" scoped>
@import "@/assets/common.scss";
.clos {
  height: 100%;
  .input {
    width: 100%;
  }
  .select {
    width: 100%;
  }
  .datepick {
    width: 100%;
  }
}
.isBind {
  color: red;
  font-size: 10px;
}
</style>
